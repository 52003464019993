import { Paged } from 'api/common/types/Page';
import Http from '../../common/services/Http';
import { GenericTypeDto, GenericTypeIconDto } from './models/GenericTypeDto';
import { GenericTypeSearchCriteria } from './models/GenericTypeSearchCriteria';
import { SelectValueGenericTypeDto } from './models/SelectValueGenericTypeDto';

class GenericTypesService {
    public catalogSupplier() {
        return Http.get<SelectValueGenericTypeDto[]>('genericTypes/catalogsupplier');
    }

    public catalogCharge() {
        return Http.get<SelectValueGenericTypeDto[]>('genericTypes/catalogcharge');
    }

    public catalogChargeByChargeId(chargeId: string) {
        return Http.get<SelectValueGenericTypeDto[]>('genericTypes/catalogcharge/'+ chargeId);
    }

    public catalogChargeBySupplierId(supplierId: string) {
        return Http.get<SelectValueGenericTypeDto[]>('genericTypes/catalogchargebysupplierid/'+ supplierId);
    }

    public getList(criteria: GenericTypeSearchCriteria) {
        return Http.get<Paged<GenericTypeDto>>('genericTypes', criteria);
    }

    public getById(id: string) {
        return Http.get<GenericTypeDto>('genericTypes/' + id);
    }

    public create(model: GenericTypeDto) {
        return Http.post<string>('genericTypes', model);
    }

    public update(model: GenericTypeDto) {
        return Http.put('genericTypes', model);
    }

    public remove(model: GenericTypeDto) {
        return Http.put('genericTypes/deactivate', model);
    }

    public catalogIcons() {
        return Http.get<GenericTypeIconDto[]>('genericTypes/icons-catalog');
    }
}

export default new GenericTypesService();