import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { GenericTypeDto, GenericTypeIconDto } from 'api/genericTypes/models/GenericTypeDto';
import Loading from 'common/services/Loading';
import GenericTypesService from 'api/genericTypes/GenericTypesService';
import Logger from 'common/services/Logger';
import Button from 'common/components/button/Button';
import styles from './GenericTypesDetails.module.scss';
import InputError from 'common/components/inputError/InputError';
import Label from 'common/components/label/Label';
import FormItem from 'common/components/formItem/FormItem';
import { useForm } from 'react-hook-form';
import { DEFAULT_INPUT_RULES_WITH_REQUIRED, LOGGER_LOG_TYPE } from 'Config';
import GreyArrow from 'assets/svg/Arrow-grey.svg';
import React, { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { Col, Row } from 'react-flexbox-grid';
import InputController from 'common/components/input/InputController';
import { useHistory, useParams } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import ColorPickerController from 'common/components/colorPicker/ColorPickerController';
import ReactTooltip from 'react-tooltip';
import RenderSVG from 'common/components/renderSVG/RenderSVG';

type Props = {
};

const GenericTypesDetails: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const history = useHistory();
    // eslint-disable-next-line
    let { type, itemId } = useParams<{ type: string, itemId: string }>();

    const [genericType, setGenericType] = useState<GenericTypeDto>();
    const [defaultIcons, setDefaultIcons] = useState<GenericTypeIconDto[]>();
    const [iconSelected, setIconSelected] = useState<GenericTypeIconDto>();
    const [isDetails, setIsDetails] = useState<boolean>(true);
    const [itemToRemove, setItemToRemove] = useState<GenericTypeDto>();
    const [dialogDeleteItemIsOpen, setDialogDeleteItemIsOpen] = React.useState(false);

    const form = useForm<GenericTypeDto>({ shouldUnregister: false });
    const { handleSubmit, errors, reset } = form;

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasGenericTypesWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_SUPPLIERS_WRITE']);

    const getGenericType = async () => {
        if (!itemId) {
            const icons = await GenericTypesService.catalogIcons();
            setDefaultIcons(icons);
            return;
        }

        Loading.show();
        try {
            const result = await GenericTypesService.getById(itemId);
            const icons = await GenericTypesService.catalogIcons();
            setDefaultIcons(icons);

            if (result) {
                if (result.iconSvg && icons?.length) {
                    const selectedIcon = icons.find(x => x.svg == result.iconSvg);
                    setIconSelected(selectedIcon);
                }

                if (result.isSystem) {
                    setIsDetails(true);
                    result.name = t('common.generic_types.' + result.name as any);
                }
            }

            reset(result);
            setGenericType(result);
            Loading.hide();
        } catch (error) {
            addToast(t('common.messages.error_load_info'), {
                appearance: 'error',
            });

            Logger.error(
                LOGGER_LOG_TYPE.REQUEST,
                `Couldn't get catalog genericType types`,
                error
            );
            Loading.hide();
        }
    }

    const showRemoveItemDialog = (item: GenericTypeDto) => {
        setItemToRemove(item);
        setDialogDeleteItemIsOpen(true);
    };

    const removeItem = async () => {
        setDialogDeleteItemIsOpen(false);
        if (!!itemToRemove) {
            try {
                Loading.show();
                await GenericTypesService.remove(itemToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                history.push(`/settings/genericTypes/list`);
                Loading.hide();
            } catch (error) {
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    useEffect(() => {
        getGenericType();
    }, []);

    useEffect(() => {
        setIsDetails(type === 'details');
    }, [type]);

    const onSubmit = async (formData: GenericTypeDto) => {
        try {

            if (!formData.name) {
                addToast(t('common.messages.complete_required_fields'), { appearance: 'warning' });
                return;
            }

            Loading.show();

            formData.iconSvg = iconSelected?.svg;

            if (genericType != null) {
                formData.id = genericType.id;
                await GenericTypesService.update(formData);
            } else {
                itemId = await GenericTypesService.create(formData);
                getGenericType();
            }

            Loading.hide();

            addToast(t('common.messages.record_save_success'), { appearance: 'success' });
            history.goBack();
        } catch (error) {
            Loading.hide();

            if (error?.response?.status === 409) {
                addToast(t('common.messages.name_already_exists'), { appearance: 'warning' });
                return;
            }

            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't create or update genericType`, error);
        }
    };

    const onGoBack = () => {
        if (genericType) {
            reset(genericType);
            if (genericType.iconSvg && defaultIcons?.length) {
                const selectedIcon = defaultIcons.find(x => x.svg == genericType.iconSvg);
                setIconSelected(selectedIcon);
            }
        }
        history.goBack();
    }

    const IconSelector = () => {
        return <div style={{ display: 'flex' }}>
            {defaultIcons?.map(icon => <div
                key={icon.name}
                onClick={() => isDetails ? undefined : setIconSelected(icon)}
                className={`${styles.iconDiv} ${isDetails ? styles.disabled : ''} ${iconSelected?.name == icon.name ? styles.selected : ''}`}>
                <RenderSVG
                    item={icon.svg}
                    className={styles.icon}
                />
            </div>)}
        </div>
    }

    return (
        <div>
            <div className={styles.pageNameContainer}>
                <div className={styles.pageNameContent} onClick={() => history.push(`/settings/genericTypes/list`)} >
                    <img className={styles.pageNameContentImage} src={GreyArrow} />
                    <span className={styles.pageNameContentText}>
                        {t('settings.generic_types')}
                    </span>
                </div>
            </div>

            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} md={12} lg={8} xl={6}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('generic_types.name')} {!isDetails ? '*' : ''}
                            </Label>
                            <InputController
                                name="name"
                                form={form as any}
                                disabled={isDetails}
                                placeholder={t('generic_types.name')}
                                rules={{ ...DEFAULT_INPUT_RULES_WITH_REQUIRED }}
                            />
                            <InputError error={errors.name} maxLength={250} />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={4} xl={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('generic_types.color')}
                            </Label>
                            <ColorPickerController
                                name="color"
                                disabled={isDetails}
                                form={form as any}
                            />
                        </FormItem>
                    </Col>
                    <Col xs={12} md={12} lg={4} xl={3}>
                        <FormItem>
                            <Label className={styles.label}>
                                {t('generic_types.icon')}
                            </Label>
                            {IconSelector()}
                        </FormItem>
                    </Col>
                </Row>
                <div className={styles.buttonContainer}>
                    <Button preset={'secondary'}
                        type='button'
                        text={t('common.cancel')}
                        onClick={() => onGoBack()} />
                    {type === 'details' && hasGenericTypesWritePolicy &&
                        <Button
                            type="button"
                            text={t('common.remove')}
                            preset={'danger'}
                            disabled={genericType?.isSystem}
                            data-id="issystem"
                            data-tip={t('generic_types.forbidden_tooltip')}
                            onClick={() => showRemoveItemDialog({ id: genericType?.id } as GenericTypeDto)} />
                    }
                    {type === 'details' && hasGenericTypesWritePolicy &&
                        <Button
                            type='button'
                            text={t('common.edit')}
                            disabled={genericType?.isSystem}
                            data-id="issystem"
                            data-tip={t('generic_types.forbidden_tooltip')}
                            onClick={() => history.push(`/settings/genericTypes/edit/${itemId}`)} />
                    }
                    {type !== 'details' && hasGenericTypesWritePolicy &&
                        <Button
                            type='submit'
                            disabled={genericType?.isSystem}
                            data-id="issystem"
                            data-tip={genericType?.isSystem ? t('generic_types.forbidden_tooltip') : undefined}
                            text={t('common.save')} />
                    }

                    <QuestionYesNo message={t('common.messages.remove_generic_type', { genericType: genericType?.name ?? '' })}
                        isVisible={dialogDeleteItemIsOpen}
                        onYes={() => removeItem()}
                        onNo={() => setDialogDeleteItemIsOpen(false)} />

                    {genericType?.isSystem && <ReactTooltip id="issystem" />}
                </div>
            </form>
        </div>
    );
};

export default GenericTypesDetails;
