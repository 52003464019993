import { removeAccents } from 'Config';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import numbro from 'numbro';

class Utils {

    public newGuid(): string {
        return uuidv4();
    }

    public objectArraybyStringPath(o: any, s: string) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        const a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    public getMonthsInRange(startDate: Date, endDate: Date) {
        let dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, 'month')) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(1, 'month');
        }

        return result;
    }

    public getMonthsInDates(dates: Date[]) {
        let dStartDate = dayjs(Math.min(...dates.map(x => x.getTime())));
        const dEndDate = dayjs(Math.max(...dates.map(x => x.getTime())));
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, 'month')) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(1, 'month');
        }

        return result;
    }

    public getYearsInRange(startDate: Date, endDate: Date) {
        let dStartDate = dayjs(startDate);
        const dEndDate = dayjs(endDate);
        const result = [];

        while (dStartDate.isBefore(dEndDate) || dStartDate.isSame(dEndDate, 'year')) {
            result.push(dStartDate.toDate());
            dStartDate = dStartDate.add(1, 'year');
        }

        return result;
    }

    public getMonthsInYear(date: Date) {
        return new Array(12).fill({}).map((_, i) => (new Date(date.getFullYear(), i, 1, 0, 0, 0, 0)));
    }

    public sortAlphabetically(arr: any[], desc?: boolean, field = 'label') {
        return arr.sort(function (a, b) {
            if (!a[field] || !b[field]) return 0;
            if (removeAccents(a[field].toLowerCase()) < removeAccents(b[field].toLowerCase())) { return desc ? 1 : -1; }
            if (removeAccents(a[field].toLowerCase()) > removeAccents(b[field].toLowerCase())) { return desc ? -1 : 1; }
            return 0;
        });
    }

    public sortStringsAlphabetically(arr: string[]) {
        return arr.sort(function (a, b) {
            if (removeAccents(a) < removeAccents(b)) { return -1; }
            if (removeAccents(a) > removeAccents(b)) { return 1; }
            return 0;
        });
    }

    public sortAlphabeticallyByKey<T, K extends keyof T>(arr: T[], key: K) {
        return arr.sort((a, b) => {
            const aValue = removeAccents(a[key] as any);
            const bValue = removeAccents(b[key] as any);
            if (aValue < bValue) { return -1; }
            if (aValue > bValue) { return 1; }
            return 0;
        });
    }

    public makeid(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    public isStringNullOrEmpty(str: string): boolean {
        if (!str) {
            return true;
        }

        return !str.trim();
    }

    private formatDatePartValue(value?: number): string {
        return `${value ? (value < 10 ? ('0' + value) : value) : '00'}`;
    }

    public generateDate(year: number, month: number, day: number, hour?: number, minutes?: number, seconds?: number): Date {
        const monthAux = this.formatDatePartValue((month + 1));
        const dayAux = this.formatDatePartValue(day);
        const hourAux = this.formatDatePartValue(hour);
        const minutesAux = this.formatDatePartValue(minutes);
        const secondsAux = this.formatDatePartValue(seconds);
        const aux = `${year}-${monthAux}-${dayAux}T${hourAux}:${minutesAux}:${secondsAux}.000Z`;
        return new Date(aux);
    }

    public flatten<T>(list: any | any[]): T[] {
        return list.reduce((a: any, b: any) => a.concat(Array.isArray(b) ? this.flatten(b) : b), []);
    }

    public formatCurrency(value: number, symbol?: string) {
        numbro.languageData().delimiters.thousands = ' ';
        numbro.languageData().delimiters.thousandsSize = 3;
        numbro.languageData().delimiters.decimal = ',';

        return numbro(value || 0).formatCurrency({
            thousandSeparated: true,
            mantissa: 2,
            // optionalMantissa: true,
            currencySymbol: !symbol ? '€' : symbol,
            currencyPosition: 'postfix',
            spaceSeparatedCurrency: true,

        });
    }

    public capitalize(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }


    public numberFormatter = (value: number) => {
        if (typeof value !== 'number') value = 0.0
        const options = {
            significantDigits: 2,
            thousandsSeparator: ' ',
            decimalSeparator: ',',
            symbol: '€'
        }

        const valueS = value.toFixed(options.significantDigits)

        const [currency, decimal] = valueS.split('.');

        return `${currency.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            options.thousandsSeparator
        )}${decimal !== '00' ? options.decimalSeparator : ''}${decimal !== '00' ? decimal : ''}`
    }

    public getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2-lat1);
        const dLon = this.deg2rad(lon2-lon1);
        const a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        const d = R * c; // Distance in km
        return d;
    }

    private deg2rad(deg: number) {
        return deg * (Math.PI/180)
    }

}

export default new Utils();
