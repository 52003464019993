import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Rect,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryLabel,
	VictoryLegend,
	VictoryLine,
} from 'victory';
import dayjs from 'dayjs';
import { ConsumeItemTypeDashboardDto, ConsumesDashboardDto } from 'api/dashboard/dtos/ConsumesDashboardDto';
import styles from './ConsumesChart.module.scss';
import Utils from 'common/services/Utils';
import { DASHBOARD_COLORS, DEFAULT_LANGUAGE, STORAGE } from 'Config';
import { DashboardConsumeTypeEnum } from 'api/dashboard/enums/DashboardConsumeTypeEnum';
import ChartLegendLabel from 'common/components/charts/ChartLegendLabel';
import Colors from 'styles/export/colors.module.scss';
import ChartEmptyLabel from 'common/components/charts/ChartEmptyLabel';
import useElementSize from 'common/hooks/useElementSize';
import Storage from 'common/services/Storage';
import { DashboardFuelTypeEnum } from 'api/dashboard/enums/DashboardFuelTypeEnum';

const BarLabel = (props: any) => (
	<VictoryLabel
		{...props}
		y={props.y - 18}
		backgroundPadding={{ top: 6, left: 8, bottom: 6, right: 8 }}
		style={{ fill: Colors.white, userSelect: 'none', fontSize: 25 }}
		backgroundStyle={{ fill: props.fill, opacity: 1 }}
		backgroundComponent={<Rect rx={10} ry={10} />}
		verticalAnchor="middle"
		renderInPortal={false}
	/>
);

type Props = {
	data: ConsumesDashboardDto;
	selectedYear: number | null;
};

export interface ConsumesDashboardHiddenDataDto {
	type: DashboardConsumeTypeEnum;
	fuelName: DashboardFuelTypeEnum;
}

const ConsumesChart = ({ data, selectedYear }: Props) => {
	const { t } = useTranslation();
	const [hiddenData, setHiddenData] = useState<ConsumesDashboardHiddenDataDto[]>([]);
	const [reRenderKey, setReRenderKey] = useState(new Date().getTime());
	const [containerRef, { width, height }] = useElementSize();
	const locale = Storage.get(STORAGE.CURRENT_LOCALE) || DEFAULT_LANGUAGE;

	useEffect(() => {
		setReRenderKey(new Date().getTime());
	}, [data, selectedYear]);

	if (!data.items || data.items.length === 0) {
		return <div className={styles.noEnoughInfoText}>{t('dashboard.no_enough_information')}</div>;
	}

	const viewWidth = 1050;
	const viewHeight = 400;

	const chartWidth = viewWidth;
	const chartHeight = viewHeight - 50;
	const barWidth = 25;

	const legendData = [
		{
			name: t('dashboard.consume_average'),
			symbol: { fill: Colors.primaryDark, type: 'minus' },
			labels: { fill: Colors.primaryDark },
			clickable: false,
		},
		...data.items.map(x => ({
			//name: t(`dashboard.consumes_types.${x.type}` as any),
			name: t(`vehicle.fuels.${x.fuelName}` as any),
			fuelName: x.fuelName,
			type: x.type,
			symbol: { fill: !hiddenData.find(a => a.type === x.type && a.fuelName === x.fuelName) ? DASHBOARD_COLORS.fuels[x.fuelName] : Colors.gray17 },
			labels: { fill: Colors.primaryDark },
			clickable: true,
		})),
	];

	let itemsData = data.items.filter(x => !hiddenData.find(h => h.type === x.type && h.fuelName === x.fuelName));

	if (selectedYear) {
		itemsData = itemsData.map(x => {
			return {
				...x,
				items: x.items.filter(s => dayjs(s.date).year() === selectedYear)
			};
		});
	}

	const allItems = Utils.flatten<ConsumeItemTypeDashboardDto>(itemsData.map(x => x.items));
	const allDates = allItems
		.map(x => x.date)
		.filter((date, i, self) => self.findIndex(d => d.getTime() === date.getTime()) === i);
	const allValues = allItems.map(x => x.value);
	const average = allValues.reduce((a, b) => a + b, 0) / allValues.length;
	const highestValue = Math.max(...allValues);

	const monthsDiff = allDates?.length ? Utils.getMonthsInDates(allDates).length : 0;

	const chartPadding = { top: 50, left: 25, right: 25, bottom: 50 };

	const onClickLegend = (propsData: any) => {
		const type = propsData.datum.type;
		const fuelName = propsData.datum.fuelName;
		if (!type) {
			return;
		}

		if (hiddenData.find(x => x.type === type && x.fuelName === fuelName )) {
			setHiddenData(hiddenData.filter(x => !(x.type === type && x.fuelName === fuelName)));
		} else {
			setHiddenData([...hiddenData, { type, fuelName }]);
		}

		setReRenderKey(new Date().getTime());
	};

	return (
		<div className={styles.chartContainer} ref={containerRef}>
			<svg width={width} height={height} viewBox={`0 0 ${viewWidth} ${viewHeight}`}>
				{/* Average Line */}
				{itemsData.length > 0 && (
					<VictoryChart
						standalone={false}
						width={chartWidth}
						height={chartHeight}
						padding={chartPadding}
						maxDomain={{ y: highestValue }}
						minDomain={{ y: 0 }}
					>
						<VictoryLine
							style={{
								data: {
									stroke: Colors.primaryDark,
									strokeWidth: 1,
									strokeDasharray: 4,
								},
							}}
							data={[
								{ x: 0, y: average },
								{ x: 1, y: average },
							]}
						/>
						<VictoryAxis
							dependentAxis
							standalone={false}
							tickValues={allValues}
							style={{
								axis: {
									stroke: 'transparent',
									fill: 'none',
								},
								ticks: {
									stroke: 'transparent',
									fill: 'none',
								},
								axisLabel: {
									stroke: 'transparent',
									fill: 'none',
								},
							}}
							tickLabelComponent={<ChartEmptyLabel />}
						/>
					</VictoryChart>
				)}

				{/* Main bars chart */}
				{itemsData.length > 0 && (
					<VictoryChart
						standalone={false}
						width={chartWidth}
						height={chartHeight}
						padding={chartPadding}
						scale={{ x: 'time' }}
						domainPadding={{ x: itemsData.find(x => x.items.length > 1) ? 50 : 500 }}
						key={'chart-' + reRenderKey}
					>
						<VictoryGroup offset={35}>
							{itemsData.map((item, i) => (
								<VictoryBar
									key={'bar-' + item.type + '-' + i}
									data={item.items}
									x="date"
									y="value"
									alignment="middle"
									style={{ data: { fill: DASHBOARD_COLORS.fuels[item.fuelName], cursor: 'pointer' } }}
									cornerRadius={{ top: barWidth / 2 }}
									barWidth={barWidth}
									labels={() => null}
									labelComponent={<BarLabel fill={DASHBOARD_COLORS.fuels[item.fuelName]} />}
									events={[
										{
											target: 'data',
											eventHandlers: {
												onMouseEnter: () => {
													return [
														{
															target: 'data',
															mutation: propsData => {
																const fill = propsData.style && propsData.style.fill;
																return fill === DASHBOARD_COLORS.fuels[item.fuelName]
																	? null
																	: {
																			style: {
																				fill: DASHBOARD_COLORS.fuels[item.fuelName],
																				cursor: 'pointer',
																			},
																	};
															},
														},
														{
															target: 'labels',
															mutation: propsData => {
																const fill = propsData.style && propsData.style.fill;
																return fill === DASHBOARD_COLORS.fuels[item.fuelName]
																	? null
																	: {
																			text: Utils.formatCurrency(propsData.datum.value),
																			style: {
																				fill: DASHBOARD_COLORS.fuels[item.fuelName],
																			},
																	};
															},
														},
													];
												},
												onMouseLeave: () => {
													return [
														{
															target: 'labels',
															mutation: () => {
																return null;
															}
														}
													];
												}
											},
										},
									]}
								/>
							))}
						</VictoryGroup>
						<VictoryAxis
							orientation="bottom"
							width={chartWidth}
							height={chartHeight}
							tickValues={allDates}
							tickFormat={x => Utils.capitalize(monthsDiff > 5 ? dayjs(x).locale(locale).format('MMMM')[0] : dayjs(x).locale(locale).format('MMMM'))}
							style={{
								tickLabels: { fill: Colors.black, userSelect: 'none', fontSize: 25 },
								axis: { stroke: Colors.gray3 },
							}}
						/>
					</VictoryChart>
				)}

				{/* Legends */}
				<VictoryLegend
					standalone={false}
					x={0}
					y={chartHeight}
					gutter={40}
					data={legendData}
					orientation={'horizontal'}
					itemsPerRow={3}
					labelComponent={<ChartLegendLabel onClick={onClickLegend} />}
					style={{ labels: { fontSize: 25 } }}
				/>
			</svg>
		</div>
	);
};

export default ConsumesChart;
